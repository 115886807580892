import Box from '@mui/material/Box';
import { useMemo, ReactNode } from 'react';
import { LivestreamPlayer } from '../../components/livestream/mux/LivestreamPlayer';
import { ContentCarousel } from '../../components/ContentCarousel';
import { usePictureInPicture } from '../../contexts/PictureInPictureContext';

const BUTTON_SX = { height: '28px', width: '28px' };

export type UseLivestreamPlayerParams = {
  livestreamId?: string;
  playbackIds?: string[];
  placeholder?: ReactNode;
};

export const useLivestreamPlayer = ({
  playbackIds,
  livestreamId,
  placeholder,
}: UseLivestreamPlayerParams) => {
  const { isPictureInPicture } = usePictureInPicture();

  const livestreamCarousel = useMemo(() => {
    if (!playbackIds || !playbackIds.length || !livestreamId) {
      return placeholder ?? undefined;
    }
    const playbackIdsReversed = [...playbackIds].reverse();

    const carouselContent = playbackIdsReversed.map((playbackId, index) => {
      return (
        <LivestreamPlayer
          key={playbackId}
          livestreamId={livestreamId}
          playbackId={playbackId}
          isLatestPlayback={index === 0}
        />
      );
    });

    return (
      <Box
        sx={{
          height: '100%',
          display: isPictureInPicture ? 'none' : 'block',
        }}
      >
        <ContentCarousel
          content={carouselContent}
          buttonSx={BUTTON_SX}
          bottom={0}
        />
      </Box>
    );
  }, [playbackIds, livestreamId, isPictureInPicture, placeholder]);

  return livestreamCarousel;
};
