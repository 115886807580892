import Box from '@mui/material/Box';
import { memo } from '../../../util/memo';
import MuxPlayer from '@mux/mux-player-react/lazy';
import {
  MuxPlayerProps,
  MuxPlayerRefAttributes,
} from '@mux/mux-player-react/.';
import { MUX_VOD_STREAM } from '../../../../functions/src/util/mux/constants';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useLivestreamStatus } from '../../../hooks/livestream/useLivestreamStatus';
import { isLive } from '../../../util/mux/livestream/isLive';
import { usePictureInPicture } from '../../../contexts/PictureInPictureContext';
import { ViewerCountDynamic } from './ViewerCountDynamic';

export type LivestreamPlayerProps = MuxPlayerProps & {
  livestreamId: string;
  isLatestPlayback?: boolean;
};

const MUX_PLAYER_STYLE = { aspectRatio: 16 / 9 } as const;

const LivestreamPlayerUnmemoized = ({
  playbackId,
  livestreamId,
  isLatestPlayback = false,
}: LivestreamPlayerProps) => {
  const [isRefReady, setIsRefReady] = useState(false);
  const muxPlayerRef = useRef<MuxPlayerRefAttributes | null>(null);
  const { status } = useLivestreamStatus(livestreamId);
  const isLivePlayer = isLive(status, isLatestPlayback);
  const { isPictureInPicture, setIsPictureInPicture } = usePictureInPicture();

  const setRef = useCallback((node: MuxPlayerRefAttributes) => {
    if (!node) {
      return;
    }
    muxPlayerRef.current = node;
    setIsRefReady(true);
  }, []);

  useEffect(() => {
    if (!isRefReady) {
      return;
    }

    const mediaControllerElement = muxPlayerRef.current?.shadowRoot
      ?.querySelector('media-theme')
      ?.shadowRoot?.querySelector('media-controller');

    if (!mediaControllerElement) {
      return;
    }

    const observer = new MutationObserver(() => {
      setIsPictureInPicture(mediaControllerElement.hasAttribute('mediaispip'));
    });

    observer.observe(mediaControllerElement, {
      attributes: true,
      attributeFilter: ['mediaispip'],
    });
    return () => {
      observer.disconnect();
    };
  }, [isRefReady, setIsPictureInPicture]);

  useEffect(() => {
    if (!isLivePlayer) {
      return;
    }

    const videoElement = muxPlayerRef.current?.shadowRoot
      ?.querySelector('media-theme')
      ?.querySelector('mux-video')
      ?.shadowRoot?.querySelector('video');

    videoElement?.play();
  }, [isLivePlayer]);

  return (
    <ViewerCountDynamic
      livestreamId={livestreamId}
      playbackId={playbackId}
      isLatestPlayback={isLatestPlayback}
      sx={{ position: 'absolute', top: 4, left: 4 }}
    >
      <Box sx={{ display: isPictureInPicture ? 'none' : 'block' }}>
        <MuxPlayer
          ref={setRef}
          loading="viewport"
          playbackId={playbackId}
          streamType={MUX_VOD_STREAM}
          style={MUX_PLAYER_STYLE}
        />
      </Box>
    </ViewerCountDynamic>
  );
};

export const LivestreamPlayer = memo(LivestreamPlayerUnmemoized);
